<!--
 * @Author: your name
 * @Date: 2022-04-12 15:33:03
 * @LastEditTime: 2022-04-12 17:07:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \syg_pc_web\src\views\Agent_SchoolNum\school_list.vue
-->
<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>学校管理</p>
        <el-button type="primary" class="btn" style="margin-left: auto;" @click="holiday(1)">学校节假日设置</el-button>
        <el-button type="primary" class="btn" @click="dialogFormVisibleschool = true" >+ 添加学校</el-button> <!-- @click="add()" -->
      </div>
      <div class="query">
        <div class="queryLeft">

          <div style="width: 200px; float: left">
            省份：
            <el-select v-model="province" class="select" clearable @change="provincese">
              <el-option v-for="item in province_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 200px; float: left">
            城市：
            <el-select v-model="city" class="select" clearable @change="areaChange">
              <el-option v-for="item in city_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 200px; float: left">县区：
            <el-select v-model="area" class="select" clearable>
              <el-option v-for="item in area_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 260px;">
            <i class="el-icon-search" style="width: 20px;"></i>
            <el-input class="select" v-model.trim="searchname" placeholder="清输入学校名">
            </el-input>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="searchStu(1)">查询</el-button>
        </div>
      </div>
      <el-table :data="stueyelist" border style="width: 100%">

        <el-table-column prop="ID" label="学校编号" width="80" align="center"></el-table-column>
        <el-table-column prop="SchoolName" label="学校名称"></el-table-column>
        <el-table-column prop="ssq" label="所在地区"></el-table-column>
        <el-table-column prop="stuNum" label="学生总数"></el-table-column>
        <el-table-column prop="sturNum" label="认证学生数"></el-table-column>
        <el-table-column prop="teaNum" label="教职工数"></el-table-column>
        <el-table-column prop="tearNum" label="认证教职工数"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="danger" plain size="mini" @click="deleteClick(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
          background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="确定删除学校吗？" center :visible.sync="deleteDialog" width="400px">
      <div slot="footer">
        <el-button @click="deleteDialog = false">取 消</el-button>
        <el-button type="primary" @click="deleteConfirm()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="添加学校" :visible.sync="dialogFormVisibleschool" top="50px" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="80px">

        <!-- <el-form-item label="昵称">
        <el-input
          v-model="form.guaName"
          style="width: 200px; float: left"
        ></el-input>
      </el-form-item> -->

        <el-form-item label="投放城市">
          <el-select v-model="form.province" placeholder="请选择省份" style="float: left;width: 35%;" @change="Province">
            <el-option v-for="item in provinceList" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
          <el-select v-model="form.city" placeholder="请选择城市" style="float: left;width: 35%;" @change="City">
            <el-option v-for="item in cityList" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=投放学校>
          <el-select v-model="form.school" placeholder="请选择学校" filterable
            style="width: 70%; float: left" @change="School">
            <el-option v-for="item in SchoolList" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放理由">
          <el-input type="textarea"  v-model="form.reason" :autosize="{ minRows: 5, maxRows: 7 }"
            style="width: fit-content; float: left ; width: 40%;" placeholder="请说明您的投放理由"></el-input>
        </el-form-item>

        <el-form-item label="联系电话">
          <el-input v-model="form.tel" style="width: 200px; float: left"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button style="margin-left: -80px;" type="primary" @click="onSubmit" >申请</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>
  import {
    machineSsq,
    stuEyeSearchList,
    schoolList,
    proviceCitySchool,
    schoolBindApply
  } from '@/api/agentApi.js'
  import {
    del_school,
  } from '@/api/agent_newApi.js'

  export default {
    data() {
      return {
        stueyelist: [], // 学校列表
        province_data: [], // 省
        province: "",
        city_data: [], // 市
        city: "",
        area_data: [], // 区
        area: "",
        currpage: 1, // 初始页
        pagesize: 10, // 每页显示条目个数
        CountNum: 0, // 总条数
        search: "",
        searchname: "",
        searchstatus: 0, //查询状态
        searchprovince: 0, //查询状态
        searchcity: 0, //查询状态
        searcharea: 0, //查询状态
        deleteDialog: false,
        del_id: '', //删除学校id
        del_school_name: '', //删除学校名称
        dialogFormVisibleschool : false,// 添加学校弹出层
        form: {
          school: '', // 投放学校
          reason: '', // 投放理由
          province: '', // 省
          city: '', // 市
          area: '', // 市
          tel: '' // 电话
        },
        regex: /^1[3456789]\d{9}$/, // 手机号正则
        provinceList: [], // 省
        cityList: [], // 市
        areaList: [], // 市
        SchoolList: [], // 校
        SchoolID: '', // 学校ID
        CityListCopy: [], // 市副本
        SchoolListCopy: [] // 校副本
      }
    },
    created() {
      if (localStorage.getItem('healthlist') != null) {
        this.currpage = parseInt(localStorage.getItem('healthlist'));
        localStorage.setItem('healthlist', null)
      }
      //   if (!isNaN(localStorage.getItem('province')) || localStorage.getItem('province') != '') {
      //     this.province = parseInt(localStorage.getItem('province'));
      //     localStorage.setItem('province', null)
      //   }
      //   if (!isNaN(localStorage.getItem('city')) || localStorage.getItem('city') != '') {
      //     this.city = parseInt(localStorage.getItem('city'));
      //     localStorage.setItem('city', null)
      //   }
      //   if (!isNaN(localStorage.getItem('area'))) {
      //     this.area = parseInt(localStorage.getItem('area'));
      //     localStorage.setItem('area', null)
      //   }
      //   if (!isNaN(localStorage.getItem('search'))) {
      //     this.searchname = parseInt(localStorage.getItem('search'));
      //     localStorage.setItem('search', null)
      //   }
    },
    mounted() {
      this.Ssq(); // 查询省市区
      this.stuList(this.currpage); // 查询省市区
      this.proviceCitySchool() // 获取省市校信息
    },
    methods: {
      reset() { //重置按钮
        this.search = '';
        this.searchname = '';
        this.searchstatus = 0;
        this.province = '';
        this.city = '';
        this.area = '';
        this.stuList(this.currpage);
      },
      handleClick(row) {
        console.log(row);
      },
      proviceCitySchool() { // 省市校
        proviceCitySchool().then(res => {
          // console.log(res);
          this.provinceList = res.data.province // 省
          this.cityList = res.data.city // 市
          this.CityListCopy = res.data.city // 市副本
          this.SchoolList = res.data.school // 校
          this.SchoolListCopy = res.data.school // 校副本
        }).catch(err => {
          console.log(err)
        })
      },
      Province(val) { // 省份选择
        // console.log(val);
        this.form.school = ''
        this.form.city = ''
        this.cityList = this.CityListCopy
        this.cityList = this.cityList.filter(item => item.ProvinceId == val)
      },
      City(val) { // 城市选择
        // console.log(val);
        this.form.school = ''
        this.SchoolList = this.SchoolListCopy
        this.SchoolList = this.SchoolList.filter(item => item.City == val)
      },
      School(val) { // 学校选择
        // console.log(val);
        this.SchoolID = val
      },
      onSubmit() { // 点击申请

        const data = {
          schoolId: this.SchoolID, // 学校ID
          remarks: this.form.reason, // 备注
          tel: this.form.tel // 电话
        }

        if (!this.regex.test(this.form.tel)) {
          this.$message('手机号输入有误')
          return false;
        }else if(data.schoolId == ""){
          this.$message('请选择学校')
          return false;
        }
        schoolBindApply(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '申请成功',
              type: 'success'
            })
            this.form.school = '' // 清空
            this.form.reason = '' // 清空
            this.form.province = '' // 清空
            this.form.city = '' // 清空
            this.form.tel = '' // 清空
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      stuList(currpage) { //查询所有学生
        const data = {
          province: this.province,
          city: this.city,
          area: this.area,
          search: this.searchname,
          limit: this.pagesize,
          page: this.currpage
        }
        schoolList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.stueyelist = res.data.List
        }).catch(err => {
          console.log(err)
        })
      },
      searchStu(currpage) { // 点击查询用户列表 只用点击查询时候 用这个方法
        this.loading = true
        this.search = this.searchname;
        this.searchstatus = 1;

        if (this.province != "") {
          this.searchprovince = this.province
        } else {
          this.searchprovince = "";
        }

        if (this.area != "") {
          this.searcharea = this.area
        } else {
          this.searcharea = "";
        }

        if (this.city != "") {
          this.searchcity = this.city
        } else {
          this.searchcity = "";
        }
        this.stuList(this.currpage);
      },

      handleCurrentChange(val) { // 分页
        if (this.search == "" && this.province == "" && this.city == "" && this.area == "") {
          this.currpage = val
          this.stuList(val)
        } else {
          if (this.searchstatus == 1) {
            this.currpage = val
            this.stuListSearchHandle(val)
          } else {
            this.currpage = val
            this.stuList(val)
          }

        }
      },

      deleteClick(row) { //点击删除按钮
        this.del_id = row.ID;
        this.del_school_name = row.SchoolName;
        this.deleteDialog = true; //打开修改弹框
      },
      deleteConfirm() {
        const data = {
          schoolid: this.del_id,
          schoolname: this.del_school_name,
        }
        console.log(data);
        del_school(data).then(res => {
          if (res.status.code == 1) {
            this.$message.success('学校删除成功');
            this.deleteDialog = false;
            this.stuList(this.currpage); // 获取节假日应用列表
          } else {
            this.$message.error(res.status.msg);
            this.deleteDialog = false;
          }
        }).catch(err => {
          console.log(err)
        })
      },
      Ssq() {
        const data = {
          ssq: 1
        }
        machineSsq(data).then(res => {
          this.province_data = res.data.province // X省
        }).catch(err => {
          console.log(err)
        })
      },
      provincese(val) {
        if (val != 0) {
          this.citySearch(val)
        }
      },
      citySearch(val) {
        const datas = {
          pid: val,
        }
        machineSsq(datas).then(res => {
          this.city_data = res.data.city;
        }).catch(err => {
          console.log(err)
        })
      },
      areaChange(val) {
        //console.log(this.)
        const datas = {
          cid: val,
        }
        this.area = '';
        machineSsq(datas).then(res => {
          this.area_data = res.data.area;
        }).catch(err => {
          console.log(err)
        })
      },
      del(row) {



      },
      holiday(type) {
        console.log('/holiday-setting', type);
        localStorage.setItem('Route', 'health') // 存跳转标识
        localStorage.setItem('healthlist', this.currpage) // 存跳转页面
        // localStorage.setItem('province', this.province) // 存跳转页面
        // localStorage.setItem('city', this.city) // 存跳转页面
        // localStorage.setItem('area', this.area) // 存跳转页面
        // localStorage.setItem('search', this.searchname) // 存跳转页面
        localStorage.setItem('hpath','/holiday-setting') // 存跳转标识
        this.$router.push({
          name: 'HolidaySetting',
          params: {

          }
        })
      },
      add(row) { // 点击详情

        localStorage.setItem('Route', 'health') // 存跳转标识
        localStorage.setItem('healthlist', this.currpage) // 存跳转页面
        // localStorage.setItem('province', this.province) // 存跳转页面
        // localStorage.setItem('city', this.city) // 存跳转页面
        // localStorage.setItem('area', this.area) // 存跳转页面
        // localStorage.setItem('search', this.searchname) // 存跳转页面
        this.$router.push({
          name: 'Agent_enterSchool',
          params: {}
        })
      },

    }
  }

</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        // width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }

</style>
